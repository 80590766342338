* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'PT Sans', sans-serif;
  }
  html, body {
            height: 100%;
            margin: 0;
  }
  .wrapper {
    min-height: 100%;
  }
  #root {
    min-height: 100%;
  }
  .home,
  .sign-up {
    display: flex;
    height: 95vh;
    align-items: center;
    justify-content: center;
    font-size: 3rem;
  }
  
  .subscribe {
    background-image: url('assets/images/title-bg2.svg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    color: #fff;
    font-size: 80px;
    text-shadow: 5px 5px 3px #4dbee0;
    padding-top: 5vh;
    padding-bottom: 5vh;
  }

  .gallery {
    background-image: url('assets/images/title-bg1.svg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    /* position: center; */
    color: #fff;
    font-size: 80px;
    text-shadow: 5px 5px 3px #65425e;
    padding-top: 5vh;
    padding-bottom: 5vh;
  }

  .apply {
    background-image: url('assets/images/title-bg3.svg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    color: #fff;
    font-size: 80px;
    text-shadow: 5px 5px 3px #8c5113;
    padding-top: 5vh;
    padding-bottom: 5vh;
  }
  
  /* .q-n-a {
    background-image: url('./images/banner-bg4.jpg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    color: #fff;
    font-size: 100px;
    text-shadow: 5px 5px 3px #1c3f64;
    padding-top: 5vh;
    padding-bottom: 5vh;
  } */
  
  .video-size video {
    object-fit: cover;
    width: auto;
    height: auto;
    max-width: 90vw;
    max-height: 90vh;
    position: fixed;
    z-index: 1;
  }

  .video-container {
    width: fit-content;
    height: fit-content;
    justify-content: center;
    display: inline-flex;
    overflow: hidden;
  }

  content {
    justify-items: center;
  }

  .cards {
    /* padding: 4rem; */
    background: #fff4ef;
  }

  h1 {
    text-align: center;
  }
  
  .cards__container {
    display: flex;
    flex-flow: column;
    align-items: center;
    max-width: 1120px;
    width: 90%;
    margin: 0 auto;
  }
  
  .cards__wrapper {
    position: relative;
    margin: 20px 0 0;
  }
  
  .cards__items {
    margin-bottom: 24px;
    min-height: 500px;
    display: flex;
    flex-flow: wrap;
    justify-content: center;
  }
  .cards__items > div {
    margin: 6px;
  }
  .cards__item {
    display: flex;
    flex: 1;
    margin: 0 0.5rem;
    border-radius: 10px;
    height: 100%;
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.5);
    /* -webkit-filter: drop-shadow(0 6px 20px rgba(50, 50, 50, 0.017)); */
    /* filter: drop-shadow(6px 6px 10px rgba(50, 50, 50, 0.017)); */
  }
  
  .cards__item__link {
    display: flex;
    flex-flow: column;
    width: 100%;
    /* box-shadow: 0 6px 1px rgba(56, 125, 255, 0.17); */
    -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
    filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
    border-radius: 10px;
    overflow: hidden;
    text-decoration: none;
  }
  
  .cards__item__pic-wrap {
    position: relative;
    width: 100%;
    /* padding-top: 100%; */
    overflow: hidden;
    height: 180%;
    min-width: 250px;
    min-height: 350px;
  }

  .cards__item__trailor {
    /* content: attr(trailer); */
    position: absolute;
    /* bottom: 50px; */
    /* right: 0; */
    /* padding: 6px 8px; */
    /* max-width: calc((100%) - 60px); */
    width: 130px;
    text-align: center;
    font-size: 100px;
    font-weight: 500;
    color: #fff;
    /* background-color: #00d188; */
    /* box-sizing: border-box; */
    /* border-top-right-radius: 10px; */
    /* border-bottom-right-radius: 10px; */
    left: 25%;
    top: 30%;
    z-index: 99;
  }

  .cards__item__premium {
    content: attr(premium);
    position: absolute;
    bottom: 10px;
    /* margin-left: 10px; */
    padding: 6px 8px;
    max-width: calc((100%) - 60px);
    width: 130px;
    text-align: center;
    font-size: 15px;
    font-weight: 500;
    color: #fff;
    background-color: #f48d1f;
    box-sizing: border-box;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    z-index: 2;
  }

  .cards__item__img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
    max-width: 100%;
    height: 100%;
    max-height: 100%;
    object-fit: cover;
    transition: all 0.2s linear;
  }
  
  .cards__item__img:hover {
    transform: scale(1.1);
  }
  
  .cards__item__info {
    padding: 20px 20px 10px;
    min-width: 270px;
  }
  
  .cards__item__text {
    color: #252e48;
    font-size: 18px;
    line-height: 24px;
  }
  
  @media only screen and (min-width: 1200px) {
    .content__blog__container {
      width: 84%;
    }
  }
  
  @media only screen and (min-width: 1070px) {
    .cards__items {
      display: flex;
      flex-flow: wrap;
    }
  }
  
  @media only screen and (max-width: 1070px) {
    .cards__item {
      margin-bottom: 2rem;
    }
  }

  .pagination-bar {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 25px;
  }

  .pagination-bar-top {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 25px;
  }